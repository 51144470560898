import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kreditarten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Das kleine ABC der Kreditarten" showCalc={false} />
            <Article>
                <p>
                    Raten-, Dispositions-, Fremdwährungskredit oder doch ein Bauspar- oder Immobiliendarlehen? Damit du
                    im Dickicht der Kreditarten den Überblick behältst und entscheiden kannst, welcher Typ am besten zu
                    deiner individuellen Situation passt, haben wir im folgenden Beitrag wichtige Infos zu verschiedenen
                    Kreditoptionen für dich zusammengefasst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Grundsätzliche Unterschiede</h2>
                <p>
                    Bei{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Krediten
                    </Link>{" "}
                    gibt es ganz viele Unterschiede, wie z.B. in ihrer Höhe, ihrer Laufzeit, der Besicherung und der
                    Bereitstellung. Es gibt{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierungen
                    </Link>{" "}
                    oder Immobilienkredite, je nachdem ob du dich für eine bereits gebaute Wohnung interessierst, oder
                    selbst etwas{" "}
                    <Link to="/artikel/bauen/" target="_blank" rel="noreferrer noopener">
                        bauen
                    </Link>{" "}
                    möchtest.
                </p>
                <p>
                    Was die <strong>Höhe</strong> der Kreditsumme angeht, solltest du beachten, dass mit dieser immer
                    auch die Höhe der Zinsen steigt. Kredite kannst du in dem Aspekt auf folgende Weise unterscheiden:
                </p>
                <ul>
                    <li>
                        <strong>Mikrokredite</strong> richten sich meist an Kreditnehmer:innen, die nur über eine
                        geringe Bonität und/oder keine Sicherheiten verfügen. (Einzelpersonen können diese Kreditart in
                        Österreich in der Regel bis zu einem Betrag von 12.500 Euro beziehen, Personengesellschaften bis
                        zu einer Summe von 25.000 Euro.)
                    </li>
                    <li>
                        Höchstens 10.000 Euro werden im Rahmen von <strong>Kleinkrediten</strong> vergeben. Diese nennt
                        man auch <strong>Sofort-</strong> oder <strong>Minikredite</strong>.
                    </li>
                    <li>
                        Nicht exakt festgelegt ist der Betrag im Fall von <strong>Mittelkrediten</strong>. Dabei handelt
                        es sich um einen Kredit zwischen dem Klein- und dem Millionenkredit.
                    </li>
                    <li>
                        Die Bezeichnung <strong>Millionenkredit</strong> ist ziemlich selbsterklärend. Dazu zählen alle
                        Kredite ab dem Betrag von einer Million Euro.
                    </li>
                </ul>
                <p>
                    Noch ein kleiner Tipp: Wenn du wissen möchtest, zu welchen Konditionen du deine gewünschte
                    Kreditsumme voraussichtlich erhältst, kannst du einen{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    verwenden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Unterscheidungen noch wichtig sind</h2>
                <p>
                    Auch die <strong>Laufzeit</strong> nimmt üblicherweise mit der Höhe des Kreditbetrages zu.
                    Unterschieden kannst du zwischen:
                </p>
                <ul>
                    <li>
                        <strong>Kurzfristige Kredite</strong>, die in einem Zeitraum von bis zu einem Jahr abzubezahlen
                        sind
                    </li>
                    <li>
                        <strong>Mittelfristige Kredite</strong>, deren Laufzeit zwischen einem und vier Jahren beträgt
                    </li>
                    <li>
                        <strong>Langfristige Kredite</strong> mit einer Laufzeit von über vier Jahren
                    </li>
                </ul>
                <p>
                    Es gibt außerdem unterschiedliche Arten der <strong>Besicherung</strong>, wie zB.{" "}
                    <strong>unbesicherte Blankokredite, teilbesicherte Kredite</strong>, die über einen Blankoanteil
                    verfügen und <strong>vollbesicherte Kredite</strong>. Die teil- und vollbesicherten Varianten
                    könntest du mit mehreren Arten von Sicherheiten abdecken, zum Beispiel durch
                </p>
                <ul>
                    <li>
                        <strong>Real- oder Sachsicherung</strong> (Immobilien, Wertpapiere, Fahrzeuge etc.)
                    </li>
                    <li>
                        <strong>Personensicherheit</strong> bzw. personelle Besicherung (Bürgschaft)
                    </li>
                    <li>
                        <strong>Sicherheits- oder Forderungsabtretung</strong>, bei der die Rechte an einer Forderung
                        von dem*der Schuldner*in an die Kreditgeber*in überschrieben werden
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Geldleihe und Kreditleihe</h2>
                <p>
                    Auch <strong>die Art, in der Kredite vergeben werden</strong>, ist ein Unterscheidungskriterium.
                    Hier kann man insbesondere die Begriffe Geld- und Kreditleihe auseinanderhalten: Im Falle der{" "}
                    <strong>Geldleihe</strong> wird ein realer Betrag entweder als einzelne Summe (bei einem
                    Ratenkredit) oder als Kreditlinie zum Abruf zur Verfügung gestellt (etwa bei einem
                    Dispositionskredit). Bei einer
                    <strong>Kreditleihe</strong> bildet die Auszahlung lediglich eine Option, wodurch es möglich wird,
                    die Kreditwürdigkeit einer natürlichen oder juristischen Person als Sicherheit zu verwenden. Dies
                    ist beispielsweise die Funktion einer Bürgschaft oder eines Avalkredits.
                </p>
                <hr />

                <h2>Verwendungszweck</h2>
                <p>
                    Auch der <strong>Verwendungszweck</strong> ist wichtig, weil hier eine Unterteilung in
                    zweckgebundene Kredite und solche ohne Zweckbindung vorgenommen werden kann. Während Auto- und
                    Betriebsmittelkredite sowie Immobilien- und Bauspardarlehen jeweils zu einem spezifischen Zweck
                    vergeben werden, steht der Betrag eines Kredits ohne Zweckbindung dem:der Kreditnehmer:in zur freien
                    Verfügung. Zu letzteren zählen Konsum-, Privat und Ratenkredite, die üblicherweise höhere Zinsen
                    aufweisen als zweckgebundene Kredite, da sie im Gegensatz zu diesen nicht besichert sind.
                </p>
                <hr />

                <h2>Kreditarten für Privatpersonen</h2>
                <p>
                    <strong>Ratenkredite</strong> verfügen in der Regel über eine Laufzeit von 12 bis 120 Monaten, das
                    Kreditvolumen beträgt zwischen 1000 und – je nach Kreditinstitut – 50 000 bzw. 100 000 Euro, die
                    Tilgung erfolgt mittels konstanter monatlicher Raten und es wird ein Fixzinssatz für die gesamte
                    Laufzeit vereinbart. Da Ratenkredite häufig für die Anschaffung von Konsumgütern in Anspruch
                    genommen werden, werden sie auch als <strong>Konsumkredite</strong> bezeichnet. Ein weiterer
                    gängiger Begriff für diesen Typ ist der des <strong>Privatkredits</strong>.
                </p>
                <p>
                    Als <strong>Immobiliendarlehen</strong> werden Kredite bezeichnet, deren Verwendungszweck im Umbau,
                    Ausbau, Neubau oder dem Kauf einer Immobilie besteht. Sie verfügen zumeist über eine Laufzeit von
                    mehreren Jahren und weisen vergleichsweise niedrige{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    auf, da die Immobilie selbst als Besicherung fungieren kann. Synonyme Bezeichnungen sind{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        <strong>Baufinanzierung</strong>
                    </Link>
                    , <strong>Real-</strong> und <strong>Hypothekarkredit</strong>.
                </p>
                <p>
                    Um sich einen ersten Überblick über voraussichtliche Kosten bei der Immobilienfinanzierung zu
                    verschaffen, bietet sich übrigens unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    an.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Bausparer – oder Bausparvertrag</h2>
                <p>
                    Anders als das Immobilien- wird das <strong>Bauspardarlehen</strong> vonseiten des Staates gefördert
                    und verfügt deswegen über äußerst günstige Konditionen. Für den Erhalt eines solchen Darlehens ist
                    der Abschluss eines Bausparvertrages obligatorisch, der einen bestimmten Zeitraum – üblicherweise
                    sechs Jahre – festschreibt, in dem Geld angespart wird. Der*die Kreditnehmer*in verfügt nach Ablauf
                    der fixierten Frist über die gesparte Summe, den Betrag des Darlehens sowie über die staatliche
                    Prämie. Die Höhe des Darlehens beträgt dabei maximal 220 000 Euro. Die Wahl des Zinsmodells steht
                    der Kreditnehmer*in hingegen frei, wobei variable Zinsen in diesem Fall über eine Beschränkung nach
                    oben und unten verfügen, was zu einer hohen Planungssicherheit führt. Auch die Laufzeit kann
                    individuell abgestimmt werden und sich auf bis zu 35 Jahre belaufen.
                </p>
                <p>
                    Wird ein Kredit in einer anderen Währung als dem Euro vergeben und werden auch die Zinsen in dieser
                    Währung beglichen, so spricht man in der Eurozone von einem <strong>Fremdwährungskredit</strong>.
                    Dieser birgt insofern ein nicht unerhebliches finanzielles Risiko, als sich der entsprechende
                    Wechselkurs zu Ungunsten der Kreditnehmer*in entwickeln kann. Dies kann insbesondere dann zu
                    erheblichen Verlusten führen, wenn die Tilgung des Fremdwährungskredits in endfälliger Form erfolgt
                    – was bedeutet, dass der gesamte Betrag am Ende der Laufzeit zurückzuerstatten ist. Während sich
                    diese Kreditvariante besonders in den 90er-Jahren großer Beliebtheit erfreute, kommt es heute kaum
                    noch zur Vergabe von Fremdwährungskrediten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kredit mit Überziehungsrahmen</h2>
                <p>
                    Der <strong>Dispositionskredit</strong>, der auch als <strong>Kontokorrentkredit</strong> bezeichnet
                    wird, ermöglicht die Überziehung eines Bankkontos innerhalb eines gewissen Rahmens. Da dabei jedoch
                    äußerst hohe Zinsen anfallen, eignet sich diese Kreditvariante nur zur kurzfristigen Überbrückung.
                    Populär ist sie dennoch, schließlich wird kein Kreditvertrag benötigt: Dispositionskredite sind
                    lediglich an ein Girokonto gebunden, Raten und Zinsen werden durch die jeweiligen Eingänge
                    beglichen.
                </p>
                <p>
                    Im Gegensatz zu den bisher diskutierten Kreditarten wird bei einem
                    <strong>Avalkredit</strong> kein Kapital zur Verfügung gestellt. Vielmehr wird dem*der
                    Kreditnehmer*in die Absicherung einer bestimmten Leistung garantiert, wofür diese*r eine
                    Avalprovision entrichtet. Die Gesamtkosten fallen dabei zumeist geringer aus als bei herkömmlichen
                    Krediten. Gängige Anwendungsbereiche auf dem privaten Sektor sind etwa der Hausbau oder die Miete
                    von Immobilien.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditarten"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditarten"} heading={"Das kleine ABC der Kreditarten"} />
        </Layout>
    );
};

export default Kreditarten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditarten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
